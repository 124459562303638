.sc-bBkKQz .sc-iuOPfm  .sc-cyZbSi {
  display: none;
}

.sc-bBkKQz {
  background-color: var(--subject-background-color);
}

.sc-fLsdZI {
  display: none;
}

button.sc-ktwOSD {
  display: none
}

.sc-eTNep  {
  opacity: 0;
}

.gnatt-grid-box {
  border: 1px solid light-dark(var(--mantine-color-dark-0), var(--mantine-color-dark-6));
}

.gnatt-row {
  overflow-x: scroll;
}