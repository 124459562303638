.tracker-controls {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
}

.tracker-control-paper {
  flex: 1;
  text-align: center;
}

.tracker-header h3 {
  color: white;
  font-size: 2rem;
  background: #60A483;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ring-container {
  transform: translateY(-55px);
  margin-bottom: -65px;
  width: 260px;
}

.tracker-inputs {
  display: flex;
  width: 100%;
  flex-direction: column;
}

h3.tracker-header-text {
  font-size: 1rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}